"use strict";

FastClick.attach(document.body);

$(function() {
	var mobileDevice = $("body").hasClass("mobile"),
		selectedExperience = $(".vip-experiences__item.active"),
		cancelScrollToPosition = false,
		windowWidth = 0;

	$(document).on("click", "button", function() {
		var topOffset = 115;

		if(mobileDevice || windowWidth < 960) {
			topOffset = 95;
		}

		if(mobileDevice || windowWidth < 768) {
			topOffset = 0;
		}

		if($(this).hasClass("button-choose-experience")) {
			$("html, body").animate({
				scrollTop: $(".vip-experiences-wrapper").offset().top - topOffset
			}, 1000);
		} else if($(this).hasClass("bob-baffert-and-mike-smith")) {
			window.location = "bob-baffert-and-mike-smith.html";
		} else if($(this).hasClass("bobby-flay")) {
			window.location = "bobby-flay.html";
		} else if($(this).hasClass("celebrity-ambassador")) {
			window.location = "purple-carpet.html";
		} else if($(this).hasClass("nbc-sports")) {
			window.location = "tv-and-golf.html";
		}
	});

	$(document).on("click", ".racing, .culinary, .carpet, .tv-golf", function(e) {
		e.preventDefault();
		if($(this).hasClass("racing")) {
			$("html, body").animate({
				scrollTop: $(".rules-racing").offset().top + $(".rules__navigation").outerHeight() - 20
			}, 750);
		} else if($(this).hasClass("culinary")) {
			$("html, body").animate({
				scrollTop: $(".rules-culinary").offset().top - 20
			}, 750);

		} else if($(this).hasClass("carpet")) {
			$("html, body").animate({
				scrollTop: $(".rules-carpet").offset().top - 20
			}, 750);

		} else if($(this).hasClass("tv-golf")) {
			$("html, body").animate({
				scrollTop: $(".rules-golf").offset().top - 20
			}, 750);
		}
	});

	$(document).on("click", ".vip-experiences__item", function() {
		$(".vip-experiences").addClass("active");
		$(".vip-experiences__item").removeClass("active");
		$(this).addClass("active");
		if(mobileDevice) {
			var that = this;
			cancelScrollToPosition = setTimeout(function() {
				$("html, body").animate({
					scrollTop: $(that).offset().top + 2
				}, 250);
			}, 750);
		}
	});

	$(document).on("dragstart", "img", function(e) {
		e.preventDefault();
	});

	$(document).on("mouseover mouseout", ".vip-experiences__item", function (e) {
		if(e.type === "mouseover" && windowWidth > 960) {
			if($(this).hasClass("active")) {
				return false;
			}

			$(".vip-experiences__item").removeClass("active");

			if(mobileDevice) {
				var that = this;

				$("html, body").animate({
					scrollTop: $(that).offset().top + 2
				}, 250);
			}
		} else if(e.type === "mouseout" && windowWidth > 960) {
			selectedExperience.addClass("active");
		}
	});

	$(window).on("scroll", function() {
		if(mobileDevice) {
			clearTimeout(cancelScrollToPosition);
		}
	});

	$(window).on("load resize", function() {
		if(mobileDevice) {
			$("button").removeClass("ripple");
		}
		windowWidth = $(window).width();
	});
});
